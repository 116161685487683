@import "@percihealth/react/css/mixins.scss";

.insured-container {
    margin-top: 24px;
    display: flex;  
    flex-wrap: wrap; 
    justify-content: space-between;
}

.service-container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
.service {
    flex: 1 1 33%;
    max-width: 33%;

    @include media-tablet-horizontal-down {
        flex: 1 1 50%;
        max-width: 50%;
    }

    @include media-mobile-horizontal-down {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

.prefferred-container {
    margin-top: 10px;
    display: flex;
}

.preffered {
    flex: 1 1 50%;
}

.note {
    height: 1px;    
}